import { createMuiTheme } from "@material-ui/core";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    sidebarWidth: number;
    bottomBarHeight: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    bottomBarHeight?: number;
  }
}

export default createMuiTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
  },
  palette: {
    primary: {
      main: "#027AC5",
    },
  },
  sidebarWidth: 355,
  bottomBarHeight: 112,
});
