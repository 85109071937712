import EventEmitter from "events";
import SyncClient from "twilio-sync";
import { useState } from "react";

export default function useClient() {
  const [client, setClient] = useState<SyncClient>(
    new EventEmitter() as SyncClient
  );

  const connect = (token: string) => {
    setClient(new SyncClient(token));
  };

  return { client, connect };
}
