const participantSize = (
  count: number,
  width: number,
  height: number,
  aspectRatio: number = 16 / 9
) => {
  // Find the maximum width at each potential row count. We will have so few
  // rows that computing obviously bad possibilities is fine.
  let maxWidth = 0;
  for (let rows = 1; rows <= count; rows++) {
    const potentialWidth = widthForRows(
      rows,
      count,
      width,
      height,
      aspectRatio
    );
    if (potentialWidth > maxWidth) {
      maxWidth = potentialWidth;
    }
  }
  return { width: maxWidth, height: maxWidth / aspectRatio };
};

const widthForRows = (
  rows: number,
  count: number,
  width: number,
  height: number,
  aspectRatio: number
) => {
  const countPerRow = Math.ceil(count / rows);
  if (width / (countPerRow * aspectRatio) >= height / rows) {
    // constrained by height
    return Math.floor((height / rows) * aspectRatio);
  } else {
    // constrained by width
    return Math.floor(width / countPerRow);
  }
};

export { participantSize };
