import { useRef, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Participant from "../participant";
import useParticipants from "../hooks/use-participants";
import useVideoContext from "../hooks/use-video-context";
import { ComponentSize } from "../../types";

import { participantSize as calculateParticipantSize } from "./utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
  })
);

export default function ParticipantGrid() {
  const gridRef = useRef<HTMLDivElement>(null!);
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [participantSize, setParticipantSize] = useState<ComponentSize | null>(
    null
  );

  useEffect(() => {
    const setSize = () => {
      setParticipantSize(
        calculateParticipantSize(
          participants.length + 1,
          gridRef.current.offsetWidth,
          gridRef.current.offsetHeight
        )
      );
    };
    setSize();

    window.addEventListener("resize", setSize);
    return () => {
      window.removeEventListener("resize", setSize);
    };
  }, [participants.length]);

  return (
    <div className={classes.container} ref={gridRef}>
      {participantSize && (
        <Participant
          participant={localParticipant}
          isLocalParticipant={true}
          setSize={participantSize}
        />
      )}
      {participantSize &&
        participants.map((participant) => {
          return (
            <Participant
              key={participant.sid}
              participant={participant}
              setSize={participantSize}
            />
          );
        })}
    </div>
  );
}
