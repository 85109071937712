import React from "react";

import Fab from "@material-ui/core/Fab";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";

import useDevices from "../../hooks/use-devices";
import useLocalVideoToggle from "../../hooks/use-local-video-toggle";

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = React.useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = React.useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Fab
      className={props.className}
      onClick={toggleVideo}
      disabled={props.disabled}
      aria-label={isVideoEnabled ? "Stop Video" : "Start Video"}
    >
      {isVideoEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
    </Fab>
  );
}
