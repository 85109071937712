import { useEffect } from "react";
import SyncClient from "twilio-sync";

import { Callback } from "../../../video/types";

export interface ConnectionError {
  message: string;
  terminal: boolean;
}

export default function useHandleConnectionError(
  client: SyncClient,
  onError: Callback
) {
  useEffect(() => {
    const onConnectionError = (client: SyncClient, error: ConnectionError) => {
      if (error) {
        onError(error);
      }
    };

    client.on("connectionError", onConnectionError);
    return () => {
      client.off("connectionError", onConnectionError);
    };
  }, [client, onError]);
}
