import React from "react";
import { Participant as IParticipant } from "twilio-video";
import ParticipantInfo from "../participant-info";
import ParticipantTracks from "../participant-tracks";

export interface ParticipantSize {
  width: number;
  height: number;
}

interface Props {
  participant: IParticipant;
  isSelected?: boolean;
  videoOnly?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  setSize?: ParticipantSize;
}

const Participant: React.FC<Props> = ({
  participant,
  isSelected,
  videoOnly,
  isLocalParticipant,
  hideParticipant,
  setSize,
}) => {
  return (
    <ParticipantInfo
      participant={participant}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
      setSize={setSize}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
};

export default Participant;
