import useVideoContext from "../use-video-context";
import useDominantSpeaker from "../use-dominant-speaker";
import useParticipants from "../use-participants";
import useSelectedParticipant from "../../video-provider/use-selected-participant";

export default function useMainParticipant() {
  const [selectedParticipant] = useSelectedParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const {
    room: { localParticipant },
  } = useVideoContext();

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return (
    selectedParticipant ||
    dominantSpeaker ||
    participants[0] ||
    localParticipant
  );
}
