import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import FaceIcon from "@material-ui/icons/Face";
import Typography from "@material-ui/core/Typography";

import useIsTrackSwitchedOff from "../hooks/use-is-track-switched-off";
import usePublications from "../hooks/use-publications";
import useTrack from "../hooks/use-track";
import useParticipantIsReconnecting from "../hooks/use-is-participant-reconnecting";
import AudioLevelIndicator from "../audio-level-indicator";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  identity: {
    position: "absolute",
    bottom: 0,
    background: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "0.1em 0.3em 0.1em 0",
    fontSize: "1.2em",
    display: "inline-flex",
    "& svg": {
      marginLeft: "0.3em",
    },
  },
  infoContainer: {
    position: "absolute",
    zIndex: 2,
    height: "100%",
    width: "100%",
  },
  reconnectingContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(40, 42, 43, 0.75)",
    zIndex: 1,
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "black",
    color: "white",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    "& svg": {
      width: "15%",
      height: "15%",
    },
  },
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children,
}: MainParticipantInfoProps) {
  const classes = useStyles();

  const publications = usePublications(participant);
  const videoPublication = publications.find((p) =>
    p.trackName.includes("camera")
  );

  const videoTrack = useTrack(videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={classes.container}
    >
      <div className={classes.infoContainer}>
        <div className={classes.identity}>
          <AudioLevelIndicator audioTrack={audioTrack} />
          <Typography variant="body1" color="inherit">
            {participant.identity}
          </Typography>
        </div>
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div className={classes.avatarContainer}>
          <FaceIcon color="inherit" />
        </div>
      )}
      {isParticipantReconnecting && (
        <div className={classes.reconnectingContainer}>
          <Typography variant="body1" style={{ color: "white" }}>
            Reconnecting...
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
}
