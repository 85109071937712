import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { VideoProvider } from "./components/video/video-provider";
import { SyncProvider } from "./components/sync/sync-provider";
import Play from "./components/play";
import AppStateProvider from "./state";
import { ConnectOptions } from "twilio-video";

const videoOptions: ConnectOptions = {
  audio: true,
  video: { height: 720, frameRate: 24, width: 1280 },
  bandwidthProfile: {
    video: {
      mode: "grid",
      maxTracks: 10,
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 176, width: 144 },
      },
    },
  },
  maxAudioBitrate: 16000, //For music remove this line
  preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
  networkQuality: { local: 1, remote: 1 },
};

const VideoApp = () => {
  return (
    <SyncProvider>
      <VideoProvider options={videoOptions}>
        <Play />
      </VideoProvider>
    </SyncProvider>
  );
};

function App() {
  return (
    <Router>
      <AppStateProvider>
        <Switch>
          <Route path="/words">
            <Words />
          </Route>
          <Route path="/room">
            <VideoApp />
          </Route>
          <Route path="/play/:shortCode" component={VideoApp} />
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </AppStateProvider>
    </Router>
  );
}

function Home() {
  return (
    <>
      <h2>Home</h2>
      <Link to="/about">About</Link>
    </>
  );
}

const Words = () => {
  const [words, setWords] = useState([]);
  useEffect(() => {
    fetch("/words")
      .then((res) => res.json())
      .then((data) => {
        setWords(data);
      });
  }, []);
  return (
    <>
      {words.map((word) => (
        <h2 key={word}>{word}</h2>
      ))}
      <Link to="/">Home</Link>
    </>
  );
};

export default App;
