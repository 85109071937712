import { useEffect, useState } from "react";
import Client from "twilio-sync";
import useSyncContext from "../use-sync-context";

export default function useClientState() {
  const { client } = useSyncContext();
  const [state, setState] = useState<Client.ConnectionState>(
    client.connectionState
  );

  useEffect(() => {
    const setConnectionState = () => {
      console.log(
        "setting connection state: ",
        client.connectionState || "disconnected"
      );
      setState(client.connectionState || "disconnected");
    };
    client.on("connectionStateChanged", setConnectionState);

    return () => {
      client.off("connectionStateChanged", setConnectionState);
    };
  }, [client]);

  return state;
}
