import Participant from "../participant";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import useMainParticipant from "../hooks/use-main-participant";
import useParticipants from "../hooks/use-participants";
import useVideoContext from "../hooks/use-video-context";
import useSelectedParticipant from "../video-provider/use-selected-participant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: "auto",
      gridArea: "1 / 2 / 2 / 3",
      zIndex: 5,
    },
    scrollContainer: {
      display: "flex",
      justifyContent: "center",
    },
    innerScrollContainer: {
      width: `${theme.sidebarWidth}px`,
      [theme.breakpoints.down("sm")]: {
        width: "auto",
        display: "flex",
      },
    },
  })
);

export default function ParticipantList() {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant] = useSelectedParticipant();
  const mainParticipant = useMainParticipant();

  if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <aside className={classes.container}>
      <div className={classes.scrollContainer}>
        <div className={classes.innerScrollContainer}>
          <Participant
            participant={localParticipant}
            isLocalParticipant={true}
          />
          {participants.map((participant) => {
            const hideParticipant = participant === mainParticipant;
            return (
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={participant === selectedParticipant}
                hideParticipant={hideParticipant}
              />
            );
          })}
        </div>
      </div>
    </aside>
  );
}
