import React, { createContext, useContext, useState } from "react";
import useActiveSinkId from "./use-active-sink-id";

const USER_NAME_KEY = "PendantGames-userName";

export interface User {
  name: string;
}

export interface StateContextType {
  getToken(name: string, room: string): Promise<string>;
  user: User | null;
  setAnonUser(name: string): void;
  logout(): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const username = window.localStorage.getItem(USER_NAME_KEY);
  const [user, setUser] = useState<User | null>(
    username ? { name: username } : null
  );

  const setAnonUser = (name: string) => {
    window.localStorage.setItem(USER_NAME_KEY, name);
    setUser({ name });
  };

  const logout = () => {
    setUser(null);
    window.localStorage.removeItem(USER_NAME_KEY);
  };

  let contextValue = {
    activeSinkId,
    setActiveSinkId,
    user,
    setAnonUser,
    logout,
    getToken: async (username, room) => {
      const headers = new window.Headers();
      const endpoint = `${process.env.REACT_APP_SERVER_HOST}/token`;
      const urlParams = new URLSearchParams(window.location.search);
      const params = new window.URLSearchParams({
        password: urlParams.get("password") || "",
        username,
        room,
      });

      return fetch(`${endpoint}?${params}`, { headers })
        .then((res) => res.json())
        .then((data) => data.token);
    },
  } as StateContextType;

  const getToken: StateContextType["getToken"] = (username, room) => {
    return contextValue
      .getToken(username, room)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
