import { createContext, ReactNode } from "react";
import SyncClient from "twilio-sync";

import useClient from "./use-client";
import useHandleConnectionError, {
  ConnectionError,
} from "./use-handle-connection-error";

/*
 *  The hooks used by the SyncProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a sync application, and they can be used any number of times.
 *  the hooks in the 'SyncProvider/' directory are intended to be used by the SyncProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

interface ISyncContext {
  client: SyncClient;
  connect: (token: string) => void;
}

export const SyncContext = createContext<ISyncContext>(null!);

interface SyncProviderProps {
  children: ReactNode;
}

export function SyncProvider({ children }: SyncProviderProps) {
  const { client, connect } = useClient();
  const onError = (error: ConnectionError) => {
    console.log("sync connection error", error.message);
    console.log("isTerminal:", error.terminal);
  };

  // Register onConnectionError callback function.
  useHandleConnectionError(client, onError);

  return (
    <SyncContext.Provider value={{ client, connect }}>
      {children}
    </SyncContext.Provider>
  );
}
