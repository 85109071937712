import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";

import useVideoContext from "../video/hooks/use-video-context";
import useSyncContext from "../sync/hooks/use-sync-context";
import useClientState from "../sync/hooks/use-client-state";
import useRoomState from "../video/hooks/use-room-state";
import { useAppState } from "../../state";
import useHeight from "../../hooks/use-height";
import { useQuery } from "../../hooks/use-query";
import { PhraseCraze } from "./phrase-craze";

interface ParamTypes {
  shortCode: string;
}

interface IGameContext {
  shortCode: string;
  username: string;
}

export const GameContext = React.createContext<IGameContext>(null!);

const Play: React.FC = () => {
  let { shortCode } = useParams<ParamTypes>();
  const username = useQuery().get("username") || "";
  const { getToken, user, logout, setAnonUser } = useAppState();
  const roomState = useRoomState();
  const clientState = useClientState();
  const { connect, getAudioAndVideoTracks } = useVideoContext();
  const { connect: syncConnect } = useSyncContext();

  React.useEffect(() => {
    getAudioAndVideoTracks();
  }, [getAudioAndVideoTracks]);

  const joinRoomClick = () => {
    getToken(username, shortCode).then((token) => {
      syncConnect(token);
      connect(token);
    });
  };

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <GameContext.Provider value={{ shortCode: shortCode, username: username }}>
      <div style={{ height }}>
        {roomState === "disconnected" || clientState !== "connected" ? (
          <>
            <h3>User name: {user?.name || "not logged in"}</h3>
            <Button onClick={joinRoomClick}>Start</Button>
            <Button onClick={logout}>Log out</Button>
            <Button onClick={() => setAnonUser(username)}>Log in</Button>
          </>
        ) : (
          <PhraseCraze />
        )}
      </div>
    </GameContext.Provider>
  );
};

export default Play;
