import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import useDocument from "../hooks/use-document";
import useGameContext from "../../hooks/use-game-context";

import Timer from "./timer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(4)}px`,
  },
  outer: {
    width: "100px",
  },
  spaceLeft: {
    marginLeft: theme.spacing(2),
  },
}));

const renderStartButton = (start: () => void) => {
  return (
    <Button
      variant="contained"
      disableElevation
      color="primary"
      onClick={start}
    >
      Start
    </Button>
  );
};

const renderRoundButtons = (
  className: string,
  skip: () => void,
  next: () => void
) => {
  return (
    <>
      <Button
        variant="contained"
        disableElevation
        color="primary"
        onClick={next}
      >
        Next
      </Button>
      <Button
        variant="outlined"
        disableElevation
        onClick={skip}
        className={className}
      >
        Skip
      </Button>
    </>
  );
};

const BottomBar: React.FC<{ className: string }> = ({ className }) => {
  const {
    document: { roundEndTime, status, playerTurn },
    start,
    skip,
    next,
    endRound,
  } = useDocument();
  const classes = useStyles();
  const { username } = useGameContext();

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.outer}>
        <Timer
          status={status}
          roundEndTime={roundEndTime}
          endRound={endRound}
        />
      </div>
      <div>
        {status === "waiting"
          ? renderStartButton(start)
          : playerTurn === username
          ? renderRoundButtons(classes.spaceLeft, skip, next)
          : `Player turn: ${playerTurn}`}
      </div>
      <div className={classes.outer}></div>
    </div>
  );
};

export default BottomBar;
