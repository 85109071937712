import React from "react";
import { SyncDocument } from "twilio-sync";

import useSyncContext from "../../../sync/hooks/use-sync-context";
import useClientState from "../../../sync/hooks/use-client-state";
import useGameContext from "../../hooks/use-game-context";
import { PhraseCrazeGame } from "../types";
import Words from "../words";

const DOCUMENT_TTL = 3600;
const ROUND_DURATION_MIN = 50;
const ROUND_DURATION_MAX = 60;

const emptyGameDocument: PhraseCrazeGame = {
  category: "Everything",
  status: "waiting",
  phrase: "",
  players: [],
  playerTurn: "",
  roundEndTime: 0,
};

const randomRoundDuration = () => {
  return (
    Math.floor(Math.random() * (ROUND_DURATION_MAX - ROUND_DURATION_MIN + 1)) +
    ROUND_DURATION_MIN
  );
};

export default function useDocument() {
  const { shortCode, username } = useGameContext();
  const [document, setDocument] = React.useState<PhraseCrazeGame>(
    emptyGameDocument
  );
  const documentRef = React.useRef<SyncDocument | null>(null);
  const clientState = useClientState();
  const { client } = useSyncContext();

  React.useEffect(() => {
    console.log(clientState, documentRef.current);
    if (clientState !== "connected" || documentRef.current) {
      return;
    }

    client
      .document({
        id: shortCode,
        ttl: DOCUMENT_TTL,
        data: emptyGameDocument,
      })
      .then((doc) => {
        // doc.removeDocument();
        console.log("connected to document: ", doc);
        setDocument(doc.data as PhraseCrazeGame);
        documentRef.current = doc;
        doc.on("removed", onRemove);
        doc.on("updated", onUpdate);
        doc.mutate((currentDoc: any) => {
          const idx = currentDoc.players.indexOf(username);
          if (idx < 0) {
            currentDoc.players.push(username);
          }
          return currentDoc;
        });
      })
      .catch((error) => {
        console.log("Error accessing document: ", error);
      });

    window.addEventListener("beforeunload", cleanup);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, [clientState, client, shortCode, username]);

  const onRemove = (args: any) => {
    console.log("doc removed, args.isLocal:", args.isLocal);
    console.log("doc removed, args.previousData: ", args.previousData);
    setDocument(emptyGameDocument);
  };

  const onUpdate = (args: any) => {
    console.log("doc updated, args.data: ", args.data);
    console.log("args.isLocal: ", args.isLocal);
    setDocument(args.data);
  };

  const cleanup = () => {
    console.log("closing document");
    setDocument(emptyGameDocument);
    documentRef.current?.mutate((currentDoc: any) => {
      const game = currentDoc as PhraseCrazeGame;
      const idx = game.players.indexOf(username);
      if (idx < 0) {
        return null;
      }
      game.players.splice(idx);
      if (game.players.length === 0) {
        return emptyGameDocument as any;
      }
      return game as any;
    });
    documentRef.current?.off("removed", onRemove);
    documentRef.current?.off("updated", onUpdate);
    documentRef.current?.close();
    documentRef.current = null;
  };

  const randomWord = () => {
    return Words[Math.floor(Math.random() * Words.length)];
  };

  const start = () => {
    documentRef.current?.mutate((currentDoc: any) => {
      const game = currentDoc as PhraseCrazeGame;
      if (game.status === "in-round") {
        return null;
      }

      game.playerTurn = username;
      game.roundEndTime = Date.now() + randomRoundDuration() * 1000;
      game.status = "in-round";
      game.phrase = randomWord();
      return game as any;
    });
  };

  const skip = () => {
    documentRef.current?.mutate((currentDoc: any) => {
      const game = currentDoc as PhraseCrazeGame;
      if (game.status === "waiting" || game.phrase !== document?.phrase) {
        return null;
      }

      game.phrase = randomWord();
      return game as any;
    });
  };

  const next = () => {
    documentRef.current?.mutate((currentDoc: any) => {
      const game = currentDoc as PhraseCrazeGame;
      if (game.status === "waiting" || game.phrase !== document?.phrase) {
        return null;
      }

      game.phrase = randomWord();
      const currentPlayerIdx = game.players.indexOf(game.playerTurn);
      const nextPlayerIdx =
        currentPlayerIdx >= game.players.length - 1 ? 0 : currentPlayerIdx + 1;
      game.playerTurn = game.players[nextPlayerIdx];
      return game as any;
    });
  };

  const endRound = () => {
    documentRef.current?.mutate((currentDoc: any) => {
      const game = currentDoc as PhraseCrazeGame;
      game.status = "waiting";
      return game as any;
    });
  };

  return { document, start, skip, next, cleanup, endRound };
}
