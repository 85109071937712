import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import useSound from "use-sound";

import { GameStatus } from "../../types";
import beepSfx from "./beep.mp3";

const calculateTimerColor = (secondsToEnd: number) => {
  if (secondsToEnd <= 0 || secondsToEnd >= 50) {
    return "#43aa8b";
  } else if (secondsToEnd < 10) {
    return "#f94144";
  } else if (secondsToEnd < 20) {
    return "#f3722c";
  } else if (secondsToEnd < 30) {
    return "#f8961e";
  } else if (secondsToEnd < 40) {
    return "#f9c74f";
  }
  return "#90be6d";
};

const pulseDelay = (secondsToEnd: number) => {
  if (secondsToEnd <= 5) {
    return 400;
  } else if (secondsToEnd <= 10) {
    return 800;
  } else if (secondsToEnd <= 20) {
    return 1200;
  } else if (secondsToEnd <= 30) {
    return 2000;
  }

  return 3000;
};

const useStyles = makeStyles((theme) => ({
  timer: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    margin: "10px",
    timerColor: "white",
    transform: "scale(1)",
    transition: "background-color 5s linear",
  },
  pulse: {
    animation: "$pulse 500ms",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(249, 65, 68, 0.7)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(249, 65, 68, 0)",
    },
    "100%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 0 rgba(249, 65, 68, 0)",
    },
  },
}));

interface Props {
  status: GameStatus;
  roundEndTime: number;
  endRound: () => void;
}

const Timer: React.FC<Props> = ({ status, roundEndTime, endRound }) => {
  const [pulse, setPulse] = React.useState(false);
  const [timerColor, setTimerColor] = React.useState("green");
  const classes = useStyles();
  const [play] = useSound(beepSfx, { volume: 0.5 });
  const timeoutRef = React.useRef<NodeJS.Timeout>(null!);

  React.useEffect(() => {
    const secondsToEnd = Math.ceil((roundEndTime - Date.now()) / 1000);
    setTimerColor(calculateTimerColor(secondsToEnd));
    if (secondsToEnd <= 0) {
      if (status === "in-round") {
        endRound();
      }
      return;
    }

    if (pulse) {
      play();
    }
    timeoutRef.current = setTimeout(() => {
      setPulse(true);
    }, pulseDelay(secondsToEnd));

    return () => clearTimeout(timeoutRef.current);
  }, [pulse, roundEndTime, status]);

  return (
    <div
      className={clsx(classes.timer, {
        [classes.pulse]: pulse,
      })}
      style={{ backgroundColor: timerColor }}
      hidden={status !== "in-round"}
      onAnimationEnd={() => {
        setPulse(false);
      }}
    />
  );
};

export default Timer;
