import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FaceIcon from "@material-ui/icons/Face";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import AudioLevelIndicator from "../audio-level-indicator";
import NetworkQualityLevel from "../network-quality-level";
import { ParticipantSize } from "../participant";

import useIsTrackSwitchedOff from "../hooks/use-is-track-switched-off";
import usePublications from "../hooks/use-publications";
import useTrack from "../hooks/use-track";
import useParticipantIsReconnecting from "../hooks/use-is-participant-reconnecting";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      "& video": {
        filter: "none",
        objectFit: "contain !important",
      },
      background: "black",
    },
    variableWidth: {
      height: 0,
      paddingTop: `${(9 / 16) * 100}%`,
    },
    innerContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    infoContainer: {
      position: "absolute",
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      width: "100%",
      background: "transparent",
      top: 0,
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "black",
      color: "white",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      "& svg": {
        width: "15%",
        height: "15%",
      },
    },
    reconnectingContainer: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(40, 42, 43, 0.75)",
      zIndex: 1,
    },
    identity: {
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      padding: "0.18em 0.3em",
      margin: 0,
      display: "flex",
      alignItems: "center",
    },
    infoRowBottom: {
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      bottom: 0,
      left: 0,
    },
    networkQualityContainer: {
      width: "28px",
      height: "28px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(0, 0, 0, 0.5)",
    },
    typeography: {
      color: "white",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },
    hideParticipant: {
      display: "none",
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  setSize?: ParticipantSize;
}

export default function ParticipantInfo({
  participant,
  children,
  isLocalParticipant,
  hideParticipant,
  setSize,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === "audio");
  const videoPublication = publications.find((p) =>
    p.trackName.includes("camera")
  );

  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const classes = useStyles();

  const sizeStyle: React.CSSProperties = setSize
    ? { width: `${setSize.width}px`, height: `${setSize.height}px` }
    : {};

  return (
    <div
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.variableWidth]: !setSize,
      })}
      style={sizeStyle}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <div className={classes.networkQualityContainer}>
          <NetworkQualityLevel participant={participant} />
        </div>
        <div className={classes.infoRowBottom}>
          <span className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography
              variant="body1"
              className={classes.typeography}
              component="span"
            >
              {participant.identity}
              {isLocalParticipant && " (You)"}
            </Typography>
          </span>
        </div>
      </div>
      <div className={classes.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className={classes.avatarContainer}>
            <FaceIcon />
          </div>
        )}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeography}>
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
