import React from "react";
import { makeStyles, styled, Theme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import useVideoContext from "../../video/hooks/use-video-context";
import useSelectedParticipant from "../../video/video-provider/use-selected-participant";
import useParticipants from "../../video/hooks/use-participants";
import useGameContext from "../hooks/use-game-context";
import ParticipantList from "../../video/participant-list";
import MainParticipant from "../../video/main-participant";
import ParticipantGrid from "../../video/participant-grid";
import ToggleVideoButton from "../../video/buttons/toggle-video-button";
import ToggleAudioButton from "../../video/buttons/toggle-audio-button";
import BottomBar from "./bottom-bar";
import useDocument from "./hooks/use-document";
import Typography from "@material-ui/core/Typography";
import { GameStatus } from "./types";
import { Participant } from "twilio-video";

const Container = styled("div")(({ theme }) => {
  return {
    position: "relative",
    height: "100%",
    display: "grid",
    gridTemplateRows: `1fr ${theme.bottomBarHeight}px`,
    backgroundColor: "black",
  };
});

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    gridTemplateColumns: `1fr`,
  },
  mainAndSidebar: {
    gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
  },
  main: {
    position: "relative",
    gridArea: "1 / 1 / 2 / 2",
  },
  sidebar: {
    gridArea: "1 / 2 / 2 / 3",
  },
  avButtons: {
    position: "absolute",
    top: "20px",
    left: "20px",
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
  },
  audioButton: {
    marginTop: "16px",
  },
  phrase: {
    color: "white",
    position: "absolute",
    padding: `0 ${theme.spacing(4)}px`,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
    width: "100%",
    fontSize: "80px",
  },
  footer: {
    gridArea: "2 / 1 / 3 / 3",
    backgroundColor: "white",
  },
  roundOverAlert: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    pointerEvents: "none",
    zIndex: 10000,
  },
  roundOverText: {
    color: "white",
    padding: `0 ${theme.spacing(2)}px`,
    background: "rgba(0, 0, 0, 0.5)",
    fontSize: "80px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const playerTurnParticipant = (
  status: GameStatus,
  playerTurn: string,
  participants: Participant[]
) => {
  if (status === "waiting") {
    return null;
  }

  return (
    participants.find((p) => {
      return p.identity === playerTurn;
    }) || null
  );
};

const PhraseCraze: React.FC = () => {
  const classes = useStyles();
  const {
    document: { playerTurn, status, phrase },
    cleanup,
  } = useDocument();
  const { username } = useGameContext();
  const { room } = useVideoContext();
  const [_participant, setSelectedParticipant] = useSelectedParticipant();
  const participants = useParticipants();
  const statusRef = React.useRef(status);
  const [displayRoundOver, setDisplayRoundOver] = React.useState(false);

  const exit = () => {
    room.disconnect();
    cleanup();
  };

  React.useEffect(() => {
    setSelectedParticipant(
      playerTurnParticipant(status, playerTurn, [
        room.localParticipant,
        ...participants,
      ])
    );
  }, [playerTurn, status]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (statusRef.current === "in-round" && status === "waiting") {
      setDisplayRoundOver(true);
      const timeout = setTimeout(() => {
        setDisplayRoundOver(false);
      }, 2000);
    }
    statusRef.current = status;

    return () => clearTimeout(timeout);
  }, [status]);

  const gridView = status !== "in-round";

  return (
    <Container className={gridView ? classes.grid : classes.mainAndSidebar}>
      <div className={classes.avButtons}>
        <ToggleVideoButton />
        <ToggleAudioButton className={classes.audioButton} />
        <Fab
          className={classes.audioButton}
          onClick={exit}
          aria-label="Exit"
          color="secondary"
        >
          <ExitToAppIcon />
        </Fab>
      </div>
      {gridView && (
        <div className={classes.main}>
          <ParticipantGrid />
        </div>
      )}
      {!gridView && (
        <>
          <div className={classes.main}>
            {status === "in-round" && playerTurn === username && (
              <Typography className={classes.phrase}>{phrase}</Typography>
            )}
            <MainParticipant />
          </div>
          <div className={classes.sidebar}>
            <ParticipantList />
          </div>
        </>
      )}

      <BottomBar className={classes.footer} />
      <div className={classes.roundOverAlert} hidden={!displayRoundOver}>
        <Typography className={classes.roundOverText}>Round Over</Typography>
      </div>
    </Container>
  );
};

export { PhraseCraze };
