import React from "react";

import Fab from "@material-ui/core/Fab";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";

import useLocalAudioToggle from "../../hooks/use-local-audio-toggle";

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  return (
    <Fab
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={props.disabled}
      aria-label={isAudioEnabled ? "Mute" : "Unmute"}
    >
      {isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
    </Fab>
  );
}
